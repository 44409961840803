import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import Toolstack from "./Toolstack";
import Techstack from "./Techstack";
import { ImPointRight } from "react-icons/im";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              KNOW WHO <span className="purple"> I'M </span>
            </h1>
            <p className="home-about-body">
              Hello!
              <br/>
              I am a passionate software developer with a strong interest in cloud technologies and extensive experience with
              <b className="purple"> JavaScript. </b>
              My expertise lies in building scalable, efficient, and user-centric solutions. I am continuously expanding my skills in cloud platforms and love to integrate modern technologies to solve real-world problems. Whether working on front-end or back-end development, I strive to write clean, maintainable code and bring creative solutions to life. 
              <br/>
              Let’s connect and collaborate on innovative projects!
              <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row >
          <p style={{ textAlign: "justify" , color : "rgb(255,255,255)", fontSize : "1.2em"}}>
              Apart from coding, some other activities that I love to do!
          </p>
        <ul style={{color : "rgb(255,255,255)", fontSize : "1.2em"}}>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "The only way to do great work is to love what you do."{" "}
          </p>
          <footer className="blockquote-footer">Steve Jobs</footer> 
        </Row>
        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />
      </Container>
    </Container>
  );
}
export default Home2;
