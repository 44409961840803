import React from "react";
import Card from "react-bootstrap/Card";


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello Everyone,
            <br />
              <span className="purple"> Gujarat, India </span> is where I'm from, and my name is <span className="purple"> Shivam Rank </span>. 
            <br/>
              Right now, I work at Town of Bridgewater as a software developer. 
            <br/>  
              I earned my Master's degree in Applied Computer Science from Dalhousie University in Halifax, Canada.
          </p>
          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
