import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import DDBS from "../../Assets/Projects/DDBS.png";
import parking from "../../Assets/Projects/Phoeix_p.jpeg";
import ShopIT from "../../Assets/Projects/ShopIT.png";
import bed_and_breakfast from "../../Assets/Projects/B&B_logo.png";
import Farmer_market from "../../Assets/Projects/Farmer_market.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on in my Master's.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={parking}
              isBlog={false}
              title="Phoenix"
              description="Phoenix is an application for online destination parking. This program allows users to market their surplus front yard space in a parking lot and make money from it. Parking spots can be reserved in advance for a certain amount of time."
              ghLink="https://github.com/Shiv239/Phoenix?trk=public_profile_project-button"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ShopIT}
              isBlog={false}
              title="ShopIT"
              description="ShopIT is a comprehensive e-commerce platform designed to replicate the functionality and scale of Amazon. It will provide a user-friendly interface for customers to browse, search, and purchase a wide variety of products. Sellers will be able to create online stores, manage their inventory, and process orders."
              ghLink="https://github.com/Shiv239/ShopIT?trk=public_profile_project-button"          
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Farmer_market}
              isBlog={false}
              title="Funded Farmers"
              description="An online B2C farmers market application called Funded Farmers allows farmers to post their produce for customers to order. The customer has two options: either pick up the produce or have it delivered. Through this method, consumers can support nearby farmers and obtain fresh produce. By eliminating the middlemen, farmers can obtain a higher price for their produce than they get from superstores."
              ghLink="https://github.com/Shiv239/Funded-farmers?trk=public_profile_project-button"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bed_and_breakfast}
              isBlog={false}
              title="Bed and Breakfast"
              description="A Multi-Cloud based Serverless bed and breakfast system. Where users may order food straight from the hotel's kitchen, reserve a room based on the amount of adults and children staying there, and ask a chatbot for recommendations on local tourist attractions."
              ghLink="https://github.com/Shiv239/Bed-Breakfast?trk=public_profile_project-button"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={DDBS}
              isBlog={false}
              title="Distributed Database Management System"
              description="When no prebuilt database is being used, a distributed database system built in Java and Maven is utilized along with the CLI. Instead of being kept in a SQL or NoSQL database, the data will be kept in a text file. In order to complete a task in the database, there will be some precise commands that must be followed. Additionally, this system has two servers that will sync and replicate each other's data."
              ghLink="https://github.com/Shiv239/Distributed-Database?trk=public_profile_project-button"
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
